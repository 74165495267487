import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { parseISO, format } from 'date-fns';

import Layout from '../components/Layout/Layout';
import SEO from '../components/SEO';
import Wrap from '../components/Wrap';
import H1 from '../components/H1';
import Markdown from '../components/Markdown';
import BackButton from '../components/BackButton';
import Updates from '../components/Updates';
import PlayButton from '../components/PlayButton';

const UpdateDetailPage = props => {
  const [showVideo, setShowVideo] = React.useState(false);
  const formattedDate = format(
    parseISO(props.data.update.createdAt),
    'dd-MM-yyyy'
  );
  return (
    <Layout>
      <SEO title={props.data.update.title} />
      <XArea>
        <Wrap>
          <XImage image={props.data.xImage.publicURL} />
          <BackButton to="/updates/" label={props.data.page.backButton} />
        </Wrap>
      </XArea>
      <Wrap>
        <ShiftedH1>{props.data.update.title}</ShiftedH1>
        {showVideo ? (
          <Video controls autoPlay>
            <source
              src={props.data.update.coverVideo.file.url}
              type={props.data.update.coverVideo.file.contentType}
            />
            Your browser does not support the video tag.
          </Video>
        ) : (
          <CoverImage>
            <Img
              alt={props.data.update.coverImage.title}
              fluid={props.data.update.coverImage.fluid}
            />
            {props.data.update.coverVideo && (
              <PlayButton onClick={() => setShowVideo(true)} />
            )}
          </CoverImage>
        )}
        <Meta>
          {formattedDate} | <span>{props.data.update.author}</span>
        </Meta>
        <Markdown
          dangerouslySetInnerHTML={{
            __html: props.data.update.text.md.html,
          }}
        />
        <H1>{props.data.page.otherTitle}</H1>
        <Updates updates={props.data.updates} />
      </Wrap>
    </Layout>
  );
};

export const query = graphql`
  query($id: String!) {
    update: contentfulUpdate(id: { eq: $id }) {
      title
      text {
        md: childMarkdownRemark {
          html
        }
      }
      coverImage {
        title
        fluid(maxWidth: 780, quality: 90) {
          ...GatsbyContentfulFluid
        }
      }
      coverVideo {
        file {
          contentType
          url
        }
      }
      createdAt
      author
    }
    page: contentfulUpdateDetailPage {
      backButton
      otherTitle
    }
    xImage: file(relativePath: { eq: "x-background.svg" }) {
      publicURL
    }
    challenges: allContentfulChallenge(
      sort: { fields: createdAt, order: DESC }
      filter: { id: { ne: $id } }
    ) {
      nodes {
        title
        tags
        slug
      }
    }
    updates: allContentfulUpdate(
      sort: { order: DESC, fields: createdAt }
      filter: { id: { ne: $id } }
      limit: 2
    ) {
      totalCount
      nodes {
        ...ContentfulUpdateListView
      }
    }
  }
`;

export default UpdateDetailPage;

const XArea = styled.div`
  background: white
    linear-gradient(
      90deg,
      rgba(217, 235, 243, 1) 0%,
      rgba(217, 235, 243, 0.13) 50%
    );
  position: relative;

  padding: 30px 0 30px;
  ${p => p.theme.m} {
    padding: 40px 0 40px;
  }
  ${p => p.theme.l} {
    padding: 40px 0 140px;
    :after {
      position: absolute;
      content: '';
      bottom: 0;
      height: 80px;
      width: calc(100% - 120px);
      max-width: 900px;
      left: 50%;
      transform: translateX(-50%);
      background: white;
    }
  }
`;

const ShiftedH1 = styled(H1)`
  text-align: left;
  ${p => p.theme.l} {
    margin-top: -20px;
    position: relative;
  }
`;

const XImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background: url('${p => p.image}');
  background-size: contain;
  background-position: top left;
  background-repeat: no-repeat;
  width: 100%;
  ~ * {
    position: relative;
  }
`;

const CoverImage = styled.div`
  position: relative;
`;

const Video = styled.video`
  width: 100%;
`;

const Meta = styled.p`
  margin: 20px 0 60px;
  font-weight: bold;
  span {
    color: ${p => p.theme.color.accent};
  }
  font-size: 14px;
  ${p => p.theme.l} {
    font-size: 16px;
  }
`;
